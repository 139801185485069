.modal-form {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
}


.select-row {
    position: relative;
    display: flex;
    justify-content: center;
}

.select-container {
    position: relative;
    width: 50%;
}

.form-row {
    position: relative;
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
}

.add_product-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1.5em 3em;
    padding-bottom: 0.25em;
}




.product_license-form {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    background-color: #c9daea;
    border: 1px solid black;
    border-radius: 10px;
}
.product_license-form > div {
    position: relative;
    padding: .5em .75em;
}
.product_license-form h1,
.product_license-form h4 {
    text-align: center;
    margin: 0;
}
.product_license-form .form-floating {
    position: relative;
    display: flex;
    justify-content: center;
    width: 50%;
    
}




.seat_select-container {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
}



.license_detail-container {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    gap: .5em;
    padding: .5em;
}
.license_detail-container > div {
    position: relative;
    flex: 1;
}
.seat_details-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;   
    /* background-color: blue; */
}
.seat_details > div {
    position: relative;
    text-align: center;
    padding: 1em 0;
    font-family: futurabold;
}
.seat_details span {
    font-size: 2rem;
    font-family: futura;
}
.user_details-container {
    position: relative;
    width: 100%;
    height: 100%;
}



.user__assign-view {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    padding: .25em .25em;    
}
.heading {
    position: relative;
    padding: .2em;
    padding-top: 0;
    text-align: center;
}
.heading h2 {
    font-family: var(--default-head-font);
    font-size: 2.25rem;
    margin: 0;
}
.body {
    position: relative;
    display: flex;
    flex-flow: column;
    flex: 1;
    padding: 0 .75em;   
}
.body h4 {
    font-size: 1.25rem;
    margin-bottom: .25em
}
.body ul {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1.25em;
    border: 1px solid black;
    border-radius: 5px;
    /* background-color: blueviolet; */
}



.license__user__item {
    position: relative;
    display: flex;    
    width: 100%;
    margin-bottom: 1.25em;
    border: 1px solid var(--primary-dark-color);
    border-radius: 0.25em;
    background-color: var(--primary-lite-dark-color);
    overflow: hidden;
    cursor: pointer;
}
.license__user__item .item-loading {
    position: absolute;
    top: 50%;
    right: .75em;
    transform: translateY(-50%);
    opacity: 0;
}
.license__user__item.is-loading .license__user__item__loading {
    opacity: 1;
}
.license__user__item__banner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--glds-green);    
    font-size: 1.5rem;
    font-family: futurabold;
    color: snow;
    z-index: 5;
    opacity: 0;
    transform-origin: left; 
    transform: translateX(100%) scale(.5);       
    transition: transform .3s ease-in-out, opacity .3s ease-in-out;
}





.service_license-form {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    background-color: #c9daea;
    border: 1px solid black;
    border-radius: 10px;
}
.service_license-form h1 {
    text-align: center;
    padding-top: .5em;
    margin: 0;
}
.service_detail-container {
    position: relative;
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
    flex: 1;
}
.agent_select-container {
    padding: 0 4em;
}
.panel-group {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
}
.panel-one {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1em;
}
.panel-two {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1em;
}
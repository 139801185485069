.software__download__view {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
}
.software__download__view .title {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 1.75em;
    padding-bottom: 0;
}
.software__download__view .title h3 {
    text-align: center;
    flex: 1;
    font-family: futuraBold;
    font-size: 3rem;
    margin: 0;
}
.software__download__view .title h4 {
    padding: .75em;    
    font-family: futuraBold;
    color: darkslategray;
    font-size: 2rem;
    margin: 0;
}
.software__download__view .content {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    gap: 1em;
    flex: 1;
    padding: 1em;
    padding-top: 0;
    padding-bottom: 3.25em;
}
.software__download__view .content > div {
    position: relative;
    display: flex;
    flex-flow: column;
    flex: 1;
    padding: .5em;
    border-radius: 10px;
    background-color: var(--primary-lite-color);
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.software__download__view .content .app__logo__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
}
.app__logo__img {
    position: relative;
    display: block;
    width: 40%;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.5));
}
.file__link__container {
    padding: .75em;
    text-align: center;    
}
/* .software__download__view #version__download__option__btn .dropdown-toggle::after {
    margin-left: .8em;
    vertical-align: 0.2em;
    
} */
.software__download__view #version__download__btn {
    background-color: var(--primary-dark-color);
    color: snow;
}
.software__download__view #version__download__option__btn {
    background-color: var(--primary-dark-color);
    color: snow;
}
#dwn__panel .download__panel__title {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: .75em;
    padding-bottom: 0;
}
#dwn__panel .download__panel__title h3{
    flex: 1;
    font-family: futuraBold;
    font-size: 3rem;
    margin: 0;
}
#dwn__panel .file__name {
    text-align: center;
}
#dwn__panel .file__name h4 {
    font-size: 0.9rem;
}


/* Documentation panel css */
#doc__panel .doc__panel__title {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: .75em;
    padding-bottom: 0;
}
#doc__panel .doc__panel__title h3{
    flex: 1;
    font-family: futuraBold;
    font-size: 3rem;
    margin: 0;
}
#doc__panel .overview {
    position: relative;
    display: flex;
    flex-flow: column;
    padding: 0 1.75em;
}
#doc__panel .overview .overview__title {
    position: relative;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    padding: .5em;
    padding-bottom: 0;
}

#doc__panel .overview .overview__title h4 {
    font-family: futuraBold;
    color: darkslategray;
    font-size: 2rem;
    margin: 0;
}
#doc__panel .documentation__options {
    flex:1;
    padding: 1.5em;
}
#doc__panel .documentation__options ol {
    list-style-type:decimal;
}
#doc__panel .documentation__options ol li {
    padding: 0.15em 0;
}
#doc__panel .documentation__options h4 {
    font-family: futuraBold;
    margin: 0;
    text-decoration: underline;
}
#doc__panel .documentation__options .option__selection {
    position: relative;
    display: flex;
    flex-flow: column;
    gap: .75em;
    width: 100%;
    padding: 0.75em;
}
#doc__panel .documentation__options .option__selection > div {
    position: relative;
    display: flex;
    align-items: center;
    gap: .5em;
    color: snow;
    background-color: royalblue;
    padding: .5em;
}
#doc__panel .documentation__options .option__selection span.mid {
    flex: 1;
}
#doc__panel .documentation__options .option__selection > div span:last-child {
    cursor: pointer;
    padding: 0.25em;
}
/* #add_product_modal-form {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column;
} */
#add_product_modal-form .select-row {
    position: relative;
    display: flex;
    justify-content: center;
}
#add_product_modal-form .select-row .select-container {
    position: relative;
    width: 50%;
}

/***** FORM ROW STYLES *****/
#add_product_modal-form .form-row {
    position: relative;
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
}
#add_product_modal-form .form-row .add_product-content {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1.5em 3em;
    padding-bottom: 0.25em;
}


/***** ADD PRODUCT LICENSE FORM STYLES *****/
#add_product_modal-form .form-row .add_product-content .product_license-form {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    background-color: #c9daea;
    border: 1px solid black;
    border-radius: 10px;
}
#add_product_modal-form .form-row .add_product-content .product_license-form > div {
    position: relative;
    padding: .5em .75em;
}
#add_product_modal-form .form-row .add_product-content .product_license-form .seat_select-container {
    position: relative;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding-top: 0;
}
#add_product_modal-form .form-row .add_product-content .product_license-form h1,
#add_product_modal-form .form-row .add_product-content .product_license-form h4 {
    text-align: center;
    margin: 0;
}
#add_product_modal-form .form-row .add_product-content .product_license-form .form-floating {
    position: relative;
    display: flex;
    justify-content: center;
    width: 50%;
    
}
/*************** LICENSES DETAIL CONTAINTER STYLES */
#add_product_modal-form .form-row .add_product-content .product_license-form .license_detail-container {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    flex: 1;
    gap: .5em;
    padding: .5em;
}
#add_product_modal-form .form-row .add_product-content .product_license-form .license_detail-container .seat_details-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;   
    /* background-color: blue; */
}
#add_product_modal-form .form-row .add_product-content .product_license-form .license_detail-container .seat_details-container .seat_details > div {
    position: relative;
    text-align: center;
    padding: 1em 0;
    font-family: futurabold;
}
#add_product_modal-form .form-row .add_product-content .product_license-form .license_detail-container .seat_details-container .seat_details span {
    font-size: 2rem;
    font-family: futura;
}
#add_product_modal-form .form-row .add_product-content .product_license-form .license_detail-container .user_details-container {
    position: relative;
    width: 100%;
    height: 100%;
}
#add_product_modal-form .form-row .add_product-content .product_license-form .license_detail-container > div {
    position: relative;
    flex: 1;
}

/* */
#add_product_modal-form .form-row .add_product-content .product_license-form .license_detail-container .user_details-container .products__users__assign-view {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    padding: .25em .25em;    
}
#add_product_modal-form .form-row .add_product-content .product_license-form .license_detail-container .user_details-container .products__users__assign-view .heading {
    position: relative;
    padding: .2em;
    padding-top: 0;
    text-align: center;
}
#add_product_modal-form .form-row .add_product-content .product_license-form .license_detail-container .user_details-container .products__users__assign-view .heading h2 {
    font-family: var(--default-head-font);
    font-size: 2.25rem;
    margin: 0;
}
#add_product_modal-form .form-row .add_product-content .product_license-form .license_detail-container .user_details-container .products__users__assign-view .body {
    position: relative;
    display: flex;
    flex-flow: column;
    flex: 1;
    padding: 0 .75em;   
}
#add_product_modal-form .form-row .add_product-content .product_license-form .license_detail-container .user_details-container .products__users__assign-view .body h4 {
    font-size: 1.25rem;
    margin-bottom: .25em
}
#add_product_modal-form .form-row .add_product-content .product_license-form .license_detail-container .user_details-container .products__users__assign-view .body ul {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1.25em;
    border: 1px solid black;
    border-radius: 5px;
    /* background-color: blueviolet; */
}


/***** ADD SERVICE LICENSE FORM STYLES *****/
#add_product_modal-form .form-row .add_product-content .service_license-form {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 100%;
    height: 100%;
    background-color: #c9daea;
    border: 1px solid black;
    border-radius: 10px;
}
#add_product_modal-form .form-row .add_product-content .service_license-form h1 {
    text-align: center;
    padding-top: .5em;
    margin: 0;
}
#add_product_modal-form .form-row .add_product-content .service_license-form .service_detail-container {
    position: relative;
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
    flex: 1;
}
#add_product_modal-form .form-row .add_product-content .service_license-form .service_detail-container > div:first-child {
    padding: 0 4em;
}
#add_product_modal-form .form-row .add_product-content .service_license-form .service_detail-container .detail_group-one {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1em;
}
#add_product_modal-form .form-row .add_product-content .service_license-form .service_detail-container .detail_group-two {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 1em;
}

#add_product_modal-form .license__user__item {
    position: relative;
    display: flex;    
    width: 100%;
    margin-bottom: 1.25em;
    border: 1px solid var(--primary-dark-color);
    border-radius: 0.25em;
    background-color: var(--primary-lite-dark-color);
    overflow: hidden;
    cursor: pointer;
}
#add_product_modal-form .license__user__item.is-error {
    background-color: var(--primary-lite-dark-color);
    animation: shake .09s linear 4;
}
#add_product_modal-form .license__user__item .license__user__item__banner {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--glds-green);    
    font-size: 1.5rem;
    font-family: futurabold;
    color: snow;
    z-index: 5;
    opacity: 0;
    transform-origin: left; 
    transform: translateX(100%) scale(.5);       
    transition: transform .3s ease-in-out, opacity .3s ease-in-out;
}
#add_product_modal-form .license__user__item .license__user__item__banner.is-error {
    background-color: transparent;
    background-color: var(--glds-red);
    opacity: .9;
}
#add_product_modal-form .license__user__item .license__user__item__banner.is-success {
    background-color: transparent;
    background-color: var(--glds-green);
    opacity: .9;
}
#add_product_modal-form .license__user__item .license__user__item__banner.is-visible {    
    opacity: 1;
    transform: translateX(0) scale(1);
    transform-origin: right;
}
#add_product_modal-form .license__user__item.is-loading {
    opacity: 0.5;
}
#add_product_modal-form .license__user__item .license__user__item__loading {
    position: absolute;
    top: 50%;
    right: .75em;
    transform: translateY(-50%);
    opacity: 0;
}
#add_product_modal-form .license__user__item.is-loading .license__user__item__loading {
    opacity: 1;
}
#add_product_modal-form .license__user__item .license__user__item__cb-wrapper {
    position: relative;
    display: flex;    
    justify-content: center;
    align-items: center;
    padding: .75em;        
}
#add_product_modal-form .license__user__item .license__user__item__cb-wrapper.is-hidden {
    width: 0;
    padding: 0;
}
#add_product_modal-form .license__user__item .license__user__item__cb-wrapper.is-hidden input { 
    opacity: 0;
}
#add_product_modal-form .license__user__item .license__user__item__cb-wrapper.is-hidden .checkmark {
    position: relative;
    transform: translateX(-100%);
}
#add_product_modal-form .license__user__item .license__user__item__cb-wrapper.is-hidden .checkmark::after {
    opacity: 0;
}
#add_product_modal-form .license__user__item .license__user__item__details-wrapper {
    position: relative;
    display: flex;
    flex-flow: column;
    padding: .5em;
    flex: 1;
}
#add_product_modal-form .license__user__item__details-wrapper span:first-child {
    font-family: futuraBold;
    font-size: 1.75rem;
    color: var(--base-font-color);
}
#add_product_modal-form .license__user__item__details-wrapper span:last-child {
    font-size: 1rem;
    color: var(--heading-light-color);
}


/**** ****/
#add_product_modal-form .form-row .form-row-col {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 2em;
}
#add_product_modal-form .form-row .product_details-column {    
    border-right: 1px outset rgba(0, 0, 0, 0.25);
}
#add_product_modal-form .form-row .product_description-column {        
    border-left: 1px inset rgba(0, 0, 0, 0.25);    
}
#add_product_modal-form .button-row {
    position: relative;
    display: flex;
    flex-wrap: nowrap;
    padding: 1em .75em;
}
#add_product_modal-form .button-row .button-container {
    display: flex;
    justify-content: center;
    width: 100%;        
}
#add_product_modal-form .button-row .button-container button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    padding: .5em;
}
#add_product_modal-form .button-container button .btn-icon {
    margin-right: 0.75em;
}





/* checkbox styles */
.checkbox-area {
    position: relative;
    height: 100%;
    
    
}                
.container {
    position: relative;
    display: flex;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
.checkmark {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 25px;
    width: 25px;
    background-color: #eee;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    border: 1px solid transparent;
    background-color: #fff;
    box-shadow: 1px 2px 5px rgb(0 0 0 / 9%);
}
.checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: #0ab438;  
}
.container:hover input ~ .checkmark {
    background-color: #fff;
}
.container input:disabled ~ .checkmark {
    opacity: 0.3;
    cursor: default;
}
.container input:checked ~ .checkmark {
    background-color: #2196F3;
    background-color: var(--glds-green);
    outline: 0;
}
.container input:checked ~ .checkmark.changed {
    background-color: #fff;
    background: linear-gradient(90deg, rgba(10,180,56,0.25) 12%, rgba(11,210,34,0.25) 25%, rgba(12,251,3,0.25) 45%, rgba(12,251,3,0.25) 55%, rgba(11,210,34,0.25) 75%, rgba(10,180,56,0.25) 87%);
    border-color: #0ab438;
}
.container input:focus ~ .checkmark {
    outline: none;
    border: none;
    box-shadow: 0 0 5px  rgb(119, 141, 169);
    border: 1px solid rgb(119, 141, 169);
}
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
.container input:checked ~ .checkmark:after {
    display: block;
}
.container .checkmark:after {
    left: 9px;
    top: 3px;
    width: 7px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}
.container .checkmark.changed:after {
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}